
import { BInput, BSelect } from 'buetify/lib/components';
import BButton from 'buetify/lib/components/button/BButton';
import BField from 'buetify/lib/components/form/field';
import { defineComponent } from 'vue';
import AlignCenterIcon from '../../../../../../components/icons/AlignCenterIcon';
import AlignLeftIcon from '../../../../../../components/icons/AlignLeftIcon';
import AlignRightIcon from '../../../../../../components/icons/AlignRightIcon';
import BoldIcon from '../../../../../../components/icons/BoldIcon';
import ItalicIcon from '../../../../../../components/icons/ItalicIcon';
import SearchIcon from '../../../../../../components/icons/SearchIcon';
import UnderlineIcon from '../../../../../../components/icons/UnderlineIcon';

const currencies = ['$', '£', '€'];

export default defineComponent({
	name: 'field-addons-example',
	components: {
		BField,
		BInput,
		BButton,
		BSelect,
		AlignLeftIcon,
		AlignCenterIcon,
		AlignRightIcon,
		BoldIcon,
		UnderlineIcon,
		ItalicIcon
	},
	setup() {
		return {
			currencies,
			SearchIcon
		};
	}
});
