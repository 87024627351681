
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import { defineComponent } from 'vue';
import ApiView from '../../../../../components/apiView/ApiView.vue';
import { ExampleView } from '../../../../../components/exampleView';
import { apis } from './apis';
import AddonsExample from './examples/AddonsExample.vue';
import AddonsExampleCode from '!!raw-loader!./examples/AddonsExample.vue';
import GroupsExample from './examples/GroupsExample.vue';
import GroupsExampleCode from '!!raw-loader!./examples/GroupsExample.vue';
import NestedGroupsExample from './examples/NestedGroupsExample.vue';
import NestedGroupsExampleCode from '!!raw-loader!./examples/NestedGroupsExample.vue';
import ObjectSyntaxExample from './examples/ObjectSyntaxExample.vue';
import ObjectSyntaxExampleCode from '!!raw-loader!./examples/ObjectSyntaxExample.vue';
import ResponsiveGroupsExampleCode from '!!raw-loader!./examples/ResponsiveGroupsExample.vue';
import ResponsiveGroupsExample from './examples/ResponsiveGroupsExample.vue';
import SimpleExample from './examples/SimpleExample.vue';
import SimpleCode from '!!raw-loader!./examples/SimpleExample.vue';
import HorizontalExample from './examples/HorizontalExample.vue';
import HorizontalExampleCode from '!!raw-loader!./examples/HorizontalExample.vue';

export default defineComponent({
	name: 'field-documentation',
	components: {
		ExampleView,
		AddonsExample,
		GroupsExample,
		ApiView,
		SimpleExample,
		ObjectSyntaxExample,
		NestedGroupsExample,
		ResponsiveGroupsExample,
		HorizontalExample,
		BHorizontalDivider
	},
	setup() {
		return {
			apis,
			SimpleCode,
			AddonsExampleCode,
			ObjectSyntaxExampleCode,
			GroupsExampleCode,
			NestedGroupsExampleCode,
			ResponsiveGroupsExampleCode,
			HorizontalExampleCode
		};
	}
});
