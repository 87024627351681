
import { BInput, BSelect } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'field-nested-groups-example',
	components: {
		BField,
		BInput,
		BSelect
	}
});
