
import { BInput } from 'buetify/lib/components';
import BButton from 'buetify/lib/components/button/BButton';
import BField from 'buetify/lib/components/form/field';
import { defineComponent } from 'vue';
import SearchIcon from '../../../../../../components/icons/SearchIcon';

export default defineComponent({
	name: 'field-groups-example',
	components: {
		BField,
		BInput,
		BButton
	},
	setup() {
		return {
			SearchIcon
		};
	}
});
