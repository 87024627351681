
import { BCheckbox, BInput } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import { defineComponent, shallowRef, computed } from 'vue';

export default defineComponent({
	name: 'field-object-syntax-example',
	components: {
		BField,
		BInput,
		BCheckbox
	},
	setup() {
		const showErrors = shallowRef(true);
		const variant = computed(() => (showErrors.value ? 'is-danger' : ''));
		return {
			showErrors,
			variant
		};
	}
});
